import { Navigate, useLocation } from 'react-router'
import { useSigninCheck } from 'reactfire'
import FullPageLoading from './FullPageLoading.container'
import React from 'react'

// eslint-disable-next-line react/prop-types
const SecureRoute = ({ children }) => {
  let location = useLocation()
  const { status, data: signInCheckResult } = useSigninCheck()

  //============================================================================
  // we can also check for claims directly
  //============================================================================
  // pass in an object describing the custom claims a user must have
  // const { status, data: signInCheckResult } = useSignInCheck({ requiredClaims: { superUser: true } });

  // // OR

  // // pass in a custom claims validator function
  // const { status, data: signInCheckResult } = useSignInCheck({
  //   validateCustomClaims: (userClaims) => {
  //     // custom validation logic...
  //     return {
  //       hasRequiredClaims: !!userClaims.superUser,
  //     };
  //   },
  // });
  //============================================================================

  // look into suspense implementation, the suspense seems to kick in most of the time, while this loader instance only loads for a split of a second
  // this might be different when on live server with higher latency for checking sign in check, perhaps caching is involved?
  // TODO: change spinner color to primary when done testing this
  if (status === 'loading') {
    return <FullPageLoading color="danger" />
  }

  if (signInCheckResult.signedIn !== true) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export default SecureRoute
