import React from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'
const FullPageLoading = ({ color }) => {
  return (
    <div className="vw-100 vh-100 d-flex flex-column justify-content-center">
      <CSpinner className="align-self-center" color={color || 'primary'} />
      {/* <p className="align-self-center">Loading...</p> */}
    </div>
  )
}

FullPageLoading.propTypes = {
  color: PropTypes.string,
}

export default FullPageLoading
