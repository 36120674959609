import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { createRoot } from 'react-dom/client'
import { getAnalytics, logEvent } from 'firebase/analytics'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const sendToAnalytics = ({ id, name, value }) => {
  const analytics = getAnalytics()
  logEvent(analytics, 'Web Vitals', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  })
}
reportWebVitals(sendToAnalytics)
