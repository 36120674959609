// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFGb_sJdHfGVOSOKo1U4u8rYIRT-4i8OU",
  authDomain: "hnh-web-d5e63.firebaseapp.com",
  projectId: "hnh-web-d5e63",
  storageBucket: "hnh-web-d5e63.appspot.com",
  messagingSenderId: "609456168384",
  appId: "1:609456168384:web:75a5bc1b2378e22e57bc25",
  measurementId: "G-CYBRVZXHHS"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)
const auth = getAuth(app)

export { app, analytics, db, auth, firebaseConfig }
