import React from 'react'
import { AuthProvider, FirebaseAppProvider, FirestoreProvider } from 'reactfire'
import { app, auth, db } from './firebase'

const withFirebaseHooks = (App) => {
  // const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  // initialize Database and Auth with the normal Firebase SDK functions
  //   const firestore = getFirestore(app)
  //   const auth = getAuth(app);
  const wrapperComponent = () => {
    return (
      <FirebaseAppProvider firebaseApp={app}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={db}>
            <App />
          </FirestoreProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    )
  }

  return wrapperComponent
}

export default withFirebaseHooks
